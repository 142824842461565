import { supabase } from './supabase.js';

let cachedSession = null;
let cachedSubscription = null;
let lastChecked = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const EXEMPT_PATHS = ['/new-user', '/getpro']; // Add other exempt paths as needed

async function getAuthState() {
    const now = Date.now();
    if (cachedSession && now - lastChecked < CACHE_DURATION) {
        return { session: cachedSession, subscription: cachedSubscription };
    }

    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
        clearAuthState();
        return { session: null, subscription: null };
    }

    cachedSession = session;
    cachedSubscription = await checkUserSubscription(session.user.email);
    lastChecked = now;

    return { session: cachedSession, subscription: cachedSubscription };
}

export async function checkAuth(currentPath = null) {
    try {
        const { session, subscription } = await getAuthState();

        currentPath = currentPath || window.location.pathname;
        const isExemptPath = EXEMPT_PATHS.some(path => currentPath.startsWith(path));

        if (session && !subscription && !isExemptPath) {
            return { session, subscription: null, shouldSignOut: true };
        }

        return { session, subscription, shouldSignOut: false };
    } catch (error) {
        return { session: null, subscription: null, shouldSignOut: false };
    }
}

async function checkUserSubscription(email) {
    try {
        const { data, error } = await supabase
            .from('subscriptions')
            .select('subscription_id, price_id, status')
            .eq('email', email)
            .eq('status', 'active')
            .maybeSingle();

        if (error || !data) {
            return null;
        }

        return data;
    } catch (error) {
        return null;
    }
}

export function clearAuthState() {
    cachedSession = null;
    cachedSubscription = null;
    lastChecked = 0;
}

export async function signOut() {
    await supabase.auth.signOut();
    clearAuthState();
    clearSession();
}

export function saveSession(session) {
    if (session && session.access_token) {
        document.cookie = `supabase-auth-token=${session.access_token}; path=/; secure; SameSite=Strict`;
    }
}

export function clearSession() {
    document.cookie = `supabase-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function showAlert(message) {
    const alertContainer = document.getElementById('alertContainer');
    const alertMessage = document.getElementById('alertMessage');
    const alertTitle = document.getElementById('alertTitle');
    
    if (alertContainer && alertMessage && alertTitle) {
        alertTitle.textContent = "Information";
        alertMessage.textContent = message;
        alertContainer.classList.remove('hidden');
    }
}

export function setupDismissAlertButton() {
    const dismissAlertButton = document.getElementById('dismissAlertButton');
    if (dismissAlertButton) {
        dismissAlertButton.addEventListener('click', () => {
            const alertContainer = document.getElementById('alertContainer');
            if (alertContainer) {
                alertContainer.classList.add('hidden');
            }
            localStorage.removeItem('alertMessage');
        });
    }
}

export async function handleCheckoutCancellation() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('canceled') === 'true') {
        await signOut();
        showAlert('Your subscription process was canceled. You can try again when you are ready.');
        return true;
    }
    return false;
}

export { supabase };